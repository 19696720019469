import { postData } from "./requests";

export const getUserInfoById = (_user_id) => {
    const url = 'personal/info';
    const requestData = {
        'userId': _user_id,
    };
    return postData(url, requestData)
}

export const getSeekerUserInfoById = (_user_id) => {
    const url = 'personal/info';
    const requestData = {
        'userId': _user_id,
        'user_type': 'seeker'
    };
    return postData(url, requestData)
}

export const updateUserInfo = (_user) => {
    const url = 'personal/update-user-info';
    const requestData = {
        'userId': _user['_id'],
        'updateData': _user
    };
    return postData(url, requestData)
}

